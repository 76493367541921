export default {
  methods: {
    newToFixed(number, decimalPlaces, times) {
      // console.log('number', number);
      const factor = Math.pow(10, decimalPlaces);
      times = times || factor
      const truncatedNumber = Math.trunc(number * times);
      // console.log('truncatedNumber', truncatedNumber);
      const result = truncatedNumber / factor;
      // console.log('result', result);
      return Number(result.toFixed(decimalPlaces));
    },
    timeDisposal(time) {
      let newTime = time.slice(5)
      return newTime.replace('-', '.')
    },
  },
}